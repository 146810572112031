import React, { useState, useEffect } from 'react';
import googleLogo from '../assets/images/google-G.png';
import './Join1Percent.css';

const GoogleReviewsSection = () => {
  const [activeReview, setActiveReview] = useState(0);
  const [direction, setDirection] = useState('next');
  const [animationState, setAnimationState] = useState('idle'); // 'idle', 'sliding-out', 'sliding-in'
  const transitionDuration = 500; // Increased for smoother transitions

  const reviews = [
    {
      author: "Taylor Machado",
      date: "1 Month Ago",
      text: "My husband and I had the best experience working with Modern Solutions! Our realtor, Ryan Harkins, provided astounding expertise and negotiation skills. He helped my husband and I secure an incredible deal for our first home and was always spot-on with his advice. I felt supported every step of the way and couldn't have asked for a better realtor! He was always available to answer any questions, kept everything organized, and was great at helping us understand all the details. I felt confident in every decision we made together! Thank you Ryan for helping to make the buying process feel easy and stress-free, especially as first time home buyers!"
    },
    {
      author: "Safraz Baksh",
      date: "A Week Ago",
      text: "I had an excellent experience working with Hannah Manesh on my recent purchase. She transformed what could have been a stressful process into a seamless and efficient one. Throughout the entire transaction, she maintained clear and consistent communication, diligently represented my best interests during negotiations, and remained in contact even beyond the closing. I highly recommend Hannah to friends and family for both buying and selling properties. Thank you, Hannah!"
    },
    {
      author: "Tyler Rich",
      date: "4 Weeks Ago",
      text: "We had a perfect experience working with Hannah at Modern Solution Realty inc. Her dedication to finding us the right place to call home was incredibly impressive. She was 100% accommodating and professional throughout the entire experience and took what could of been a stressful time and turned it into something streamline and easy. I would recommend Hannah to absolutely everyone. BEST REALTOR EVER."
    },
    {
      author: "Maddie Bautista",
      date: "3 Months Ago",
      text: "We came across Modern Solution when family recommended their services to us. Ryan was our realtor -- who we strongly recommend! My partner and I were first time homebuyers and Ryan facilitated a frictionless, professional, efficient, and fun process. He was so easy to get in touch with when we had any questions, and was a great negotiator as well. A gesture that my partner and I really appreciated was when Ryan noticed that we liked a piece of furniture in the home that we bought, he sent a link of that product to us in case we wanted to buy our own. It really speaks to Ryan's attention to detail and thoughtfulness. If you're a FTHB, do get in touch with Modern Solution and work with Ryan!"
    },
    {
      author: "Vince Amores",
      date: "2 Weeks Ago",
      text: "From our very first interaction, Ryan was down-to-earth, funny, and incredibly supportive, instantly making us feel comfortable. He turned a stressful situation into an opportunity, helping us on the path to homeownership again. His professionalism, knowledge, and negotiation skills were absolutely amazing. Ryan was determined and committed to finding the right home for our family. Despite a few disappointing offers that didn’t go our way, Ryan never gave up. He stayed by our side, working tirelessly until we found the perfect home that met all our needs. The journey wasn’t easy—between financial advisors and legal paperwork—but it was all worth it in the end."
    },
    {
      author: "Ata Uğur",
      date: "6 Months Ago",
      text: "Ryan was crucial in helping me secure my new home! Hard working and determined guy. Went to numerous listings with me to make sure we got the right home. Couldn't recommend him enough!"
    },
    {
      author: "Incredible Foxes",
      date: "4 Weeks Ago",
      text: "Buying and selling real estate can often be a stressful and overwhelming process, but Hannah Manesh made the experience seamless and enjoyable. Her extensive knowledge, prompt communication, and dedication to negotiating in my best interest truly set her apart. I highly recommend Hannah to anyone looking for a reliable and skilled real estate professional."
    },
    {
      author: "Miss Nikbe",
      date: "2 Months Ago",
      text: "This was my first time working with an agent to sell a property. Neha Verman made the whole process easy and convenient for me. She was very professional and knowledgeable about the process and these skills make her a desirable contact for any buyer or seller."
    }
  ];

  const changeReview = (newIndex, slideDirection = 'next') => {
    if (animationState !== 'idle') return;
    
    setDirection(slideDirection);
    setAnimationState('sliding-out');
    
    setTimeout(() => {
      setActiveReview(newIndex);
      setAnimationState('sliding-in');
      
      setTimeout(() => {
        setAnimationState('idle');
      }, transitionDuration);
    }, transitionDuration);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeReview((activeReview + 1) % reviews.length, 'next');
    }, 7000); // Increased to give more time to read before auto-advancing
    
    return () => clearInterval(interval);
  }, [activeReview, animationState]);

  const handleNext = () => {
    changeReview((activeReview + 1) % reviews.length, 'next');
  };

  const handlePrev = () => {
    changeReview((activeReview - 1 + reviews.length) % reviews.length, 'prev');
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  // Calculate previous and next review indices
  const prevIndex = (activeReview - 1 + reviews.length) % reviews.length;
  const nextIndex = (activeReview + 1) % reviews.length;

  // Animation classes
  const centerCardClass = `join-reviews-card join-reviews-center ${animationState === 'sliding-out' ? `slide-${direction}-out` : ''} ${animationState === 'sliding-in' ? `slide-${direction}-in` : ''}`;
  
  const prevCardClass = `join-reviews-card join-reviews-side join-reviews-prev-card ${direction === 'prev' && animationState !== 'idle' ? 'side-active' : ''}`;
  
  const nextCardClass = `join-reviews-card join-reviews-side join-reviews-next-card ${direction === 'next' && animationState !== 'idle' ? 'side-active' : ''}`;
  
  const mobileCardClass = `join-reviews-card join-reviews-mobile-only ${animationState === 'sliding-out' ? `slide-${direction}-out` : ''} ${animationState === 'sliding-in' ? `slide-${direction}-in` : ''}`;

  return (
    <div className="join-1-percent-reviews-section">
      <div className="join-reviews-container">
        <h2 className="styled-heading">What Our Clients <span className="accent">Are Saying</span></h2>
        
        {/* Desktop Carousel */}
        <div className="join-reviews-carousel join-reviews-desktop-only">
          {/* Previous Review - Faded Left */}
          <div 
            className={prevCardClass}
            onClick={() => handlePrev()}
          >
            <div className="join-reviews-header">
              <img src={googleLogo} alt="Google" className="join-reviews-google-logo" />
              <div className="join-reviews-rating">
                <div className="join-reviews-stars">
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                </div>
              </div>
            </div>
            <div className="join-reviews-content">
              <div className="join-reviews-author">{reviews[prevIndex].author}</div>
              <div className="join-reviews-date">{reviews[prevIndex].date}</div>
              <p className="join-reviews-text">{truncateText(reviews[prevIndex].text, 150)}</p>
            </div>
          </div>
          
          {/* Current Active Review - Center */}
          <div className={centerCardClass}>
            <div className="join-reviews-progress">
              {reviews.map((_, index) => (
                <div 
                  key={index}
                  className={`join-reviews-progress-dot ${index === activeReview ? 'join-reviews-active' : ''}`}
                  onClick={() => changeReview(index, index < activeReview ? 'prev' : 'next')}
                />
              ))}
            </div>
            
            <div className="join-reviews-header">
              <img src={googleLogo} alt="Google" className="join-reviews-google-logo" />
              <div className="join-reviews-rating">
                <div className="join-reviews-stars">
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                </div>
                <span className="join-reviews-rating-text">4.8 stars (240 total reviews)</span>
              </div>
            </div>

            <div className="join-reviews-content">
              <div className="join-reviews-author">{reviews[activeReview].author}</div>
              <div className="join-reviews-date">{reviews[activeReview].date}</div>
              <p className="join-reviews-text">{truncateText(reviews[activeReview].text, 300)}</p>
            </div>
            
            <div className="join-reviews-footer">
              <div className="join-reviews-read-more">
                <a href="https://g.co/kgs/cEEnYUg" target="_blank" rel="noopener noreferrer">Read All Reviews</a>
              </div>
              <div className="join-reviews-navigation">
                <button onClick={handlePrev} className="join-reviews-nav-button join-reviews-prev">←</button>
                <button onClick={handleNext} className="join-reviews-nav-button join-reviews-next">→</button>
              </div>
            </div>
          </div>
          
          {/* Next Review - Faded Right */}
          <div 
            className={nextCardClass}
            onClick={() => handleNext()}
          >
            <div className="join-reviews-header">
              <img src={googleLogo} alt="Google" className="join-reviews-google-logo" />
              <div className="join-reviews-rating">
                <div className="join-reviews-stars">
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                  <span className="join-reviews-star">★</span>
                </div>
              </div>
            </div>
            <div className="join-reviews-content">
              <div className="join-reviews-author">{reviews[nextIndex].author}</div>
              <div className="join-reviews-date">{reviews[nextIndex].date}</div>
              <p className="join-reviews-text">{truncateText(reviews[nextIndex].text, 150)}</p>
            </div>
          </div>
        </div>
          
        {/* Mobile Review Card */}
        <div className={mobileCardClass}>
          <div className="join-reviews-header">
            <img src={googleLogo} alt="Google" className="join-reviews-google-logo" />
            <div className="join-reviews-rating">
              <div className="join-reviews-stars">
                <span className="join-reviews-star">★</span>
                <span className="join-reviews-star">★</span>
                <span className="join-reviews-star">★</span>
                <span className="join-reviews-star">★</span>
                <span className="join-reviews-star">★</span>
              </div>
              <span className="join-reviews-rating-text">4.8 stars (233 total reviews)</span>
            </div>
          </div>

          <div className="join-reviews-content">
            <div className="join-reviews-author">{reviews[activeReview].author}</div>
            <div className="join-reviews-date">{reviews[activeReview].date}</div>
            <p className="join-reviews-text">{truncateText(reviews[activeReview].text, 200)}</p>
          </div>
          
          <div className="join-reviews-footer">
            <div className="join-reviews-read-more">
              <a href="https://g.co/kgs/cEEnYUg" target="_blank" rel="noopener noreferrer">Read All Reviews</a>
            </div>
            <div className="join-reviews-navigation">
              <button onClick={handlePrev} className="join-reviews-nav-button join-reviews-prev">←</button>
              <button onClick={handleNext} className="join-reviews-nav-button join-reviews-next">→</button>
            </div>
          </div>
          
          <div className="join-reviews-progress mobile-progress">
            {reviews.map((_, index) => (
              <div 
                key={index}
                className={`join-reviews-progress-dot ${index === activeReview ? 'join-reviews-active' : ''}`}
                onClick={() => changeReview(index, index < activeReview ? 'prev' : 'next')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleReviewsSection;