import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from './SEO';
import Footer from './Footer';
import { FaPlay, FaCheck, FaArrowRight, FaCheckCircle, FaTools } from 'react-icons/fa';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';
import ContactForm from './ContactForm';
import OnePercentListsSection from './OnePercentListsSection';
import GoogleReviewsSection from './GoogleReviewsSection'; // Import the new component
import './Join1Percent.css';
import './GoogleReviewsSection.css';
import PhoneButton from './PhoneButton';

const Join1Percent = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  return (
    <div className="join-1-percent">
      <SEO
        title="Join the 1% | Modern Solution Realty | 1% Commission Real Estate"
        description="Join Modern Solution's team of top real estate professionals offering 1% commission listings and $5,000 buyer cashback. Grow your business with our proven model."
        keywords={[
          '1% commission real estate',
          'real estate agent opportunities',
          'join Modern Solution',
          'real estate career',
          '$5000 cashback model',
          'Modern Solution Realty',
        ]}
        ogImage="https://modernsolution.ca/join-1-percent-image.jpg"
        url="https://modernsolution.ca/join-1-percent"
      />

      {/* Hero Section */}
<div className="join-1-percent-hero">
  <div className="join-1-percent-hero-content">
    <div className="hero-text-container">
      <h1 className="hero-main-title">
        <span className="hero-text-line">Join The</span>
        <span className="hero-accent-line">
          <span className="accent-text">1%</span>
          <span className="accent-glow"></span>
        </span>
      </h1>
      <p className="hero-subtitle">Where Top Producers Thrive</p>
    </div>
  </div>
</div>

      {/* Two Column Video Section */}
      <div className="join-1-percent-video-section">
        <div className="join-1-percent-left-column">
          <h2>
            <span className="primary-accent">1 Percent Lists</span>
            <span className="secondary-accent"> Real Estate</span>
          </h2>
          
          <div className="founder-message">
            <p>"More Listings, More Buyers, More Commissions - In Today's Market, Value Wins!"</p>
            <p className="founder-name">Hamid Intezam, Founder of Modern Solution Realty</p>
          </div>
        </div>

        <div className="join-1-percent-right-column">
          <div className="compact-video-container">
            {!isVideoPlaying && (
              <div className="video-play-button" onClick={handlePlayVideo}>
                <FaPlay className="video-play-icon" />
              </div>
            )}
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              controls
              playsInline={true}
              preload="metadata"
              poster={require('../assets/images/video-poster-2.png')}
              onPlay={() => setIsVideoPlaying(true)}
              onPause={() => setIsVideoPlaying(false)}
            >
              <source src={require('../assets/videos/video.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      {/* Replaced Two Column Info Section with new component */}
      <OnePercentListsSection />
      
      {/* Google Reviews Section - Added after 1 Percent Lists section */}
      <GoogleReviewsSection />

      {/* How This Helps You Section - Updated with image on left and title repositioned */}
<div className="join-1-percent-help-section">
  <div className="help-section-inner">
    <div className="help-section-content">
      {/* Portrait Image on the left side */}
      <div className="help-image-container">
        <img 
          src={require('../assets/images/join-1-percent-2.jpg')} 
          alt="Real Estate Success" 
          className="help-image" 
        />
      </div>
      
      {/* Benefits content on the right */}
      <div className="help-benefits-container">
        {/* Updated heading with styled class */}
        <h2 className="styled-heading">How This Helps You</h2>
        
        <ul className="help-benefits-list">
          <li className="benefit-item">
            <div className="benefit-icon-container">
              <FaCheck className="benefit-icon" />
            </div>
            <div className="benefit-content">
              <strong>Increases buyer demand</strong>Buyers prefer agents who help them save money.
            </div>
          </li>
          <li className="benefit-item">
            <div className="benefit-icon-container">
              <FaCheck className="benefit-icon" />
            </div>
            <div className="benefit-content">
              <strong>Boosts conversions</strong>Cashback helps with closing costs, home upgrades, or moving expenses, making buyers more likely to purchase.
            </div>
          </li>
          <li className="benefit-item">
            <div className="benefit-icon-container">
              <FaCheck className="benefit-icon" />
            </div>
            <div className="benefit-content">
              <strong>More referrals & repeat business</strong>Happy clients will spread the word, bringing you more leads.
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  {/* Bottom line moved outside the blue box */}
  <div className="bottom-line-container">
    <p className="bottom-line styled-heading">
      Bottom line? The more <span className="accent">value</span> you provide, the more <span className="accent">business</span> you generate!
    </p>
  </div>
</div>

      {/* Why Smart Agents Are Switching Section */}
      <div className="join-1-percent-smart-agents-section">
  <h2 className="styled-heading">Why Smart Agents Are Switching to <span className="accent">Modern Solution Realty</span></h2>
        
        <div className="smart-agents-grid">
          <div className="smart-agents-item">
            <div className="checkmark-container">
              <FaCheckCircle className="checkmark-icon" />
            </div>
            <div className="smart-agents-content">
              <h3>High-Volume, High-Income Model</h3>
              <p>With a more affordable commission structure, you work with more clients and earn consistent, growing commissions rather than waiting for occasional high-paying deals.</p>
            </div>
          </div>
          
          <div className="smart-agents-item">
            <div className="checkmark-container">
              <FaCheckCircle className="checkmark-icon" />
            </div>
            <div className="smart-agents-content">
              <h3>Stand Out In Today's Economy</h3>
              <p>With rising financial concerns, homebuyers and sellers are actively searching for ways to save. Our model makes it easy for them to choose you over traditional agents charging full commissions.</p>
            </div>
          </div>
        </div>
        
        <div className="tools-success-container">
          <div className="tools-success-content">
            <div className="tools-success-header">
              <h3>We Provide The Tools For Your Success!</h3>
            </div>
            
            <ul className="tools-list">
              <li>
                <div className="tool-icon-container">
                  <FaTools className="tool-icon" />
                </div>
                <span><strong>High Quality Leads</strong>We invest in digital marketing to keep your pipeline full.</span>
              </li>
              <li>
                <div className="tool-icon-container">
                  <FaTools className="tool-icon" />
                </div>
                <span><strong>AI-Driven CRM & Automation</strong>Save time and manage transactions more efficiently.</span>
              </li>
              <li>
                <div className="tool-icon-container">
                  <FaTools className="tool-icon" />
                </div>
                <span><strong>Broker Support & Training</strong>Get expert guidance to maximize your earnings.</span>
              </li>
            </ul>
          </div>
          
          {/* Team Image on the right side */}
          <div className="team-image-container">
            <img 
              src={require('../assets/images/join-1-percent-1.jpg')} 
              alt="Modern Solution Realty Team" 
              className="team-image" 
            />
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="join-1-percent-contact-section">
  <h2 className="styled-heading">Ready to Join the <span className="accent">1%</span>? <br></br>Get Started Today!</h2>

        
        <div className="contact-grid">
          {/* Contact Information Box */}
          <div className="contact-info-box">
            <h2>Contact Information</h2>
            <div className="contact-divider"></div>
            
            <a href="tel:9058975000" className="contact-item">
              <Phone className="contact-icon" />
              <span>(905) 897-5000</span>
            </a>
            
            <a href="/contact" className="contact-item">
              <Mail className="contact-icon" />
              <span>info@modernsolution.ca</span>
            </a>

            <a href="#" className="contact-item">
              <Clock className="contact-icon" />
              <span>Open from 9:00 AM - 5:00 PM</span>
            </a>
            
            <a 
              href="https://www.google.com/maps/search/?api=1&query=3466+Mavis+Rd+Unit+1,+Mississauga,+ON+L5C+1T8" 
              target="_blank" 
              rel="noopener noreferrer"
              className="contact-item"
            >
              <MapPin className="contact-icon" />
              <span>3466 Mavis Rd Unit 1, Mississauga, ON L5C 1T8</span>
            </a>
          </div>

          {/* Contact Form */}
          <div className="contact-form-container">
            <h2>Send Us A Message</h2>
            <div className="contact-divider"></div>
            <ContactForm />
          </div>
        </div>
      </div>
      <PhoneButton />      
      <Footer />
    </div>
  );
};

export default Join1Percent;